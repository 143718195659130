import Image from "next/image";
import { ImageStaticAdsProps } from "./types";
import classes from "./ImageStaticAds.module.scss";

export const ImageStaticAds = ({ src, alt, href, title }: ImageStaticAdsProps) => {
    return (
        <a
            className={classes.ImageStaticAds}
            href={href}
            title={title}
            target="_blank"
            rel="noopener noreferrer"
        >
            <Image src={src} alt={alt} fill loading="lazy" unoptimized />
        </a>
    );
};